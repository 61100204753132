<template>
  <v-navigation-drawer v-model="inputValue" fixed right temporary>
    <v-row align="center" class="ma-0 pa-3 flex-no-wrap">
      <v-btn aria-label="Close" class="ml-4" icon @click="toggleDrawer">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-row>
    <v-divider />
    <v-list>
      <v-list-item v-for="(item, i) in items" :key="i" :to="item.to" :href="item.href">
        <v-list-item-title v-text="item.text" />
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState, mapMutations } from 'vuex';

export default {
  data: () => ({
    items: [
      {
        to: '/',
        text: 'Hem',
      },
      {
        to: '/stugan',
        text: 'Stugan',
      },
      {
        to: '/activities',
        text: 'Att göra',
      },
      {
        href: 'https://www.airbnb.com/h/majostuganfunasfjallen',
        text: 'Pris/Bokning',
      },
      {
        to: '/trivsel',
        text: 'Boendeinfo',
      },
    ],
  }),

  computed: {
    ...mapState(['drawer']),
    inputValue: {
      get() {
        return this.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
  },

  methods: {
    ...mapMutations(['setDrawer', 'toggleDrawer']),
  },
};
</script>
